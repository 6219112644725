import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Button, Typography } from '@mui/material';

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
    const maintaining = false;//　調整中ならtrueにする
    return (
        <Layout>
            <Seo title="会員登録" robotsType="noindex" />
            <Typography variant="h2">正会員申込みページ</Typography>
            <Typography>ここに会員制度の説明を書く</Typography>
            {maintaining ? <Typography color="error">システムメンテナンスのため、一時的に申し込みの受付を停止しております。</Typography> : <></>}
            <Button
                color="primary" variant="contained" style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                href="/certification"
                disabled={maintaining}
            >
                申し込む
            </Button>

        </Layout>
    )
}

export default IndexPage